import { defineStore } from 'pinia'

export const useCallStore = defineStore('call', {
	state: () => ({
		audioIn: [],
		audioOut: [],
		videoIn: [],
		waitingParticipants: [],
		showParticipantsSidebar: false,
		selectedSpeaker: null,
		selectedMic: null,
		selectedCamera: null,
		devicePermissions: null,
		spokenLanguage: 'en'
	}),

	actions: {
		initDevices({ audioIn, audioOut, videoIn }) {
            this.audioIn = audioIn;
            this.audioOut = audioOut;
            this.videoIn = videoIn;
		},

		setWaitingParticipants(participants = []) {
			this.waitingParticipants = participants;
		},

		toggleParticipantsSidebar() {
			this.showParticipantsSidebar = !this.showParticipantsSidebar;
		},

		setSelectedSpeaker(speaker) {
			this.selectedSpeaker = speaker;
		},

		setSelectedMic(mic) {
			this.selectedMic = mic;
		},

		setSelectedCamera(cam) {
			this.selectedCamera = cam;
		},

		setDevicePermissions(permisisons) {
			this.devicePermissions = permisisons;
		},
		setSpokenLanguage(language_code) {
			this.spokenLanguage = language_code;
		}
	}
})