<template>
    <main v-if="!isExpired" class="container-fluid mt-4 d-flex justify-content-center">

        <div class="card">
            <div class="card-img-top" v-if="participant" style="height: 300px">
                <template v-if="!isMonitoringMode">
                    <video-tile
                    v-if="(!cameraBlocked || !micBlocked)"
                    :participant="participant"
                    :independent="true"
                    :fallback-username="userName"
                 />
                <DevicePermissions
                    v-if="(cameraBlocked && micBlocked)"
                    :participant="participant"
                    :independent="true"
                    :fallback-username="userName"
                />
                </template>
               
                
            </div>
            <div class="card-body pt-0">
                <div class="row mb-2 mt-0">
                    <div class="col-md-12 border-bottom d-flex justify-content-between">
                        <div>
                            <Dropdown
                                v-for="control in callControls"
                                :key="control.key"
                                :label="control.label"
                                :icon="participant?.[control.key] ? control.icon : control.alertantiveIcon"
                                :hide-collapse="true"
                                :btn-callback="control.callback"
                                class="me-2"
                                :is-disabled="isMonitoringMode"
                            />

                            <div class="btn-group me-2" v-if="isScreenShareSupported()">
                                <button
                                    :disabled="!participant || isMonitoringMode"
                                    type="button" class="btn waves-effect waves-light d-flex flex-column px-1"
                                    data-bs-toggle="modal" data-bs-target="#effects"
                                    
                                >
                                    <i class="ti ti-sun fs-4 p-0 pb-1"></i>
                                    <span>{{ $t("background") }}</span>
                                </button>
                            </div>
                        </div>

                        <div class="d-flex align-items-center gap-1">
                            <span>{{ $t("ui_language") }}: </span>
                            <LangSelect />
                        </div>
                    </div>
                </div>

                <div class="row mb-2" v-if="!isMonitoringMode">
                    <div class="col-md-12">
                        <label for="exampleFormControlInput1" class="form-label first-letter-capitalize">
                            {{ $t("name") }}<small class="text-danger">*</small>
                        </label>
                        <input type="text" class="form-control" id="exampleFormControlInput1" v-model="userName" />
                        <small class="text-danger" v-if="!userName">
                            <span class="text-capitalize">{{ $t("name") }}</span> {{ $t("is_required") }}
                        </small>
                    </div>
                </div>

                <template v-if="participant && !isMonitoringMode">
                    <SpokenLanguage 
                        :participant="participant" :display-type="'form'" />
                </template>

                <div class="row mb-2" v-if="!micBlocked">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-between">
                            <label for="exampleFormControlInput1" class="form-label first-letter-capitalize">{{ $t("microphone") }}</label>
                            <MicTesting v-if="participant" :participant="participant" />
                        </div>
                        
                        <select class="form-select" @change="updateMic" :disabled="isMonitoringMode">
                            <option
                                v-for="mic in audioIn"
                                :key="mic.deviceId"
                                :value="mic.deviceId"
                            >
                                {{ mic.label }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row mb-2" v-if="!micBlocked">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-between">
                            <label class="form-label first-letter-capitalize">{{ $t("speaker") }}</label>

                            <SpeakerTest />
                        </div>
                        <select class="form-select" @change="updateSpeaker">
                            <option
                                v-for="speaker in audioOut"
                                :key="speaker.deviceId"
                                :value="speaker.deviceId"
                            >
                                {{ speaker.label }}
                            </option>
                        </select>

                    </div>
                </div>

                <div class="row mb-2" v-if="!cameraBlocked">
                    <div class="col-md-12">
                        <label class="form-label first-letter-capitalize">{{ $t("camera") }}</label>
                        <select class="form-select" @change="updateCamera" :disabled="isMonitoringMode">
                            <option
                                v-for="camera in videoIn"
                                :key="camera.deviceId"
                                :value="camera.deviceId"
                            >
                                {{ camera.label }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <button
                            type="button"
                            class="btn btn-primary first-letter-capitalize"
                            @click="initJoin"
                            :disabled="!userName"
                        >
                            {{ joinBtnTxt }}
                        </button>
                    </div>
                </div>

            </div>
        </div>

        <Effects v-if="participant" :participant="participant" :user-name="userName" />

        <!-- Device permissions enable info modal -->
        <button
            ref="devicePermissionInfoBtn" class="d-none" 
            data-bs-toggle="modal" data-bs-target="#device-permissions-info"
        ></button>
        <DevicePermissionInfo :camera-blocked="cameraBlocked" :mic-blocked="micBlocked" />
    </main>
</template>

<script>
// Pinia Store
import { mapActions, mapState } from 'pinia';
import { useCallStore } from '@/stores/call';
import Swal from 'sweetalert2';
// composables
import { useBackgroundEffects } from "@/composables/useBackgroundEffects";

// Components
import VideoTile from "../../components/Dailyco/VideoTile.vue";
import Dropdown from "@/components/common/Dropdown.vue";
import MicTesting from "@/components/common/MicTesting.vue";
import Effects from "@/components/Dailyco/Effects/Effects.vue";
import SpeakerTest from "@/components/common/SpeakerTest.vue";
import LangSelect from "@/components/common/LangSelect.vue";
import DevicePermissions from "@/components/Dailyco/DevicePermissions.vue";
import DevicePermissionInfo from "@/components/Dailyco/DevicePermissionInfo.vue";
import SpokenLanguage from '@/components/Dailyco/SpokenLanguage.vue';

// Utils
import { BG_FILTER } from '@/stores/constants';
import {isNotificationSupported, isScreenShareSupported} from "../../utils/Common";
export default {
    name: "PreJoin",
    components: {
        VideoTile,
        Dropdown,
        MicTesting,
        Effects,
        SpeakerTest,
        LangSelect,
        DevicePermissions,
        DevicePermissionInfo,
        SpokenLanguage
    },

    setup() {
        // Composable usage
        const { selectEffect } = useBackgroundEffects();
        return { selectEffect };
    },

    data() {
        const self = this;
        return {
            isHost: false,
            userName: "",
            roomUrl: "",
            callObject: null,
            participant: null,
            callControls: [
                { key: "video", alertantiveIcon: "video-off", icon: "video", label: "camera", callback: () => self.toggleVideo() },
                { key: "audio", alertantiveIcon: "microphone-off", icon: "microphone", label: "mic", callback: () => self.toggleAudio() },
                // { key: "effects", icon: "sun", alertantiveIcon: "sun", label: "Effects" },
            ],
            testSoundPlaying: false,
            isExpired : false,
            isMonitoringMode : false,
            isScreenShareSupported,
        }
    },

    computed: {
        ...mapState(useCallStore, ['audioIn', 'audioOut', 'videoIn', 'devicePermissions', 'spokenLanguage']),
        devicesBlocked() {
            return this.micBlocked && this.cameraBlocked;
        },

        micBlocked() {
            return !this.devicePermissions?.microphone;
        },

        cameraBlocked() {
            return !this.devicePermissions?.camera;
        },

        joinBtnTxt() {
            let text = this.$t("join");

            if (this.isHost || this.$route.query.isHost) {
                text = this.$t("join") + " " + this.$t("as_host");
            }

            if (this.micBlocked) text = `${this.$t('join')} ${this.$t('without')} ${this.$t('microphone')}`;
            if (this.cameraBlocked) text = `${this.$t('join')} ${this.$t('without')} ${this.$t('camera')}`;
            if (this.micBlocked && this.cameraBlocked) {
                text = `${this.$t('join')} ${this.$t('without')} ${this.$t('microphone')} ${this.$t('and')} ${this.$t('camera')}`;
            }

            if(this.isMonitoringMode) {
                text = "Enter in Monitoring Mode"
            }

            return text;
        }
    },

    mounted() {
        this.userName = this.$route.query.userName;
        this.roomUrl = this.$route.query.roomUrl;

        const parsedUrl = new URL(this.roomUrl);
        const searchParams = new URLSearchParams(parsedUrl.search);

        this.isHost = searchParams.has("t");

        // Assign in data obj for future reference
        this.callObject = window.dailyCo;

        this.setupPrejoinScreen(searchParams.get("t") ?? '');

        this.callObject
            .on("participant-joined", this.updateParticpants)
            .on("participant-updated", this.updateParticpants)
            .on("participant-left", this.updateParticpants)
        
        this.checkEventValidity();
    },
    created() {
        this.isMonitoringMode = this.$route.query.monitor ? true : false;
    },

    unmounted() {
        this.callObject
            .off("participant-joined", this.updateParticpants)
            .off("participant-updated", this.updateParticpants)
            .off("participant-left", this.updateParticpants)
    },

    methods: {
        ...mapActions(useCallStore, ['initDevices', 'setDevicePermissions', 'setSpokenLanguage']),
        initJoin() {
            // Ask user to show notifications
            if(isNotificationSupported()) {
                Notification.requestPermission().then(permission => {
                if (permission === "granted") console.info("User allowed notifications.") 
            });

            }
            
            this.$router.replace({
                path: `/event/${this.$route.query.eventId}`,
                query: {
                    ...this.$route.query,
                    userName: this.userName,
                    appState: this.isHost ? 'incall' : 'request_access'
                }
            });
        },

        async getDevices() {
            try {
                const audioIn = [];
                const audioOut = [];
                const videoIn = [];
                const devicesArr = await this.callObject.enumerateDevices();
                console.log("all devices", devicesArr);
                for (const d of (devicesArr?.devices ?? [])) {
                    if (d?.kind === 'audioinput') {
                        audioIn.push(d);
                    } else if (d?.kind === 'audiooutput') {
                        audioOut.push(d);
                    } else if (d?.kind === 'videoinput') {
                        videoIn.push(d);
                    }
                }
                if(audioOut.length == 0) {
                    audioOut.push({
                        deviceId : "notsupported",
                        kind : "audiooutput",
                        label : "System Default",
                        groupId : "default"
                    })
                }
                this.initDevices({ audioIn, audioOut, videoIn });
            } catch (error) {
                // handle error
                console.log(error)
            }
        },

        async setupPrejoinScreen(token) {
            try {
                const preAuthOptions =  { url: this.roomUrl };

                if (token) preAuthOptions.token = token;

                await this.callObject.preAuth(preAuthOptions);

                if(this.isMonitoringMode) {
                    await this.callObject.startCamera({
                        videoSource: false,
                        audioSource: false
                    });
                    sessionStorage.setItem("videoOn", false);
                    sessionStorage.setItem("videoOn", false);
                }
                else {
                    await this.callObject.startCamera({
                    videoSource: JSON.parse(sessionStorage.getItem("videoOn")),
                    audioSource: JSON.parse(sessionStorage.getItem("audioOn"))
                    });
                }

               

                await this.checkDevicePermissions();
                await this.getDevices();

                this.participant = this.callObject.participants().local;
            } catch (e) {
                // console.log(e)
                // handle error
            }
        },

        async checkDevicePermissions() {
            const camPermission = await navigator.permissions.query({ name: "camera" })
            const micPermission = await navigator.permissions.query({ name: "microphone" });

            const permissions = {
                camera: camPermission.state === 'granted',
                microphone: micPermission.state === 'granted'
            };
            // console.log({ permissions })
            this.setDevicePermissions(permissions);

            return permissions;
        },

        async updateMic(e) {
            await this.callObject.setInputDevicesAsync({ audioDeviceId: e.target.value });
        },

        async updateSpeaker(e) {
            if(e.target.value == "notsupported") {
                return null;
            }
            await this.callObject.setOutputDeviceAsync({ outputDeviceId: e.target.value })
        },

        async updateCamera(e) {
            await this.callObject.setInputDevicesAsync({ videoDeviceId: e.target.value });
        },

        updateParticpants(e) {
            this.participant = e.participant;
        },

        // Toggle local microphone in use (on/off)
        async toggleAudio() {
            if (this.micBlocked) {
                this.$refs.devicePermissionInfoBtn?.click()
                return;
            }

            if (!this.participant?.tracks?.audio?.persistentTrack) {
                const micPermission = await navigator.permissions.query({ name: "microphone" })
                if (micPermission.state === "granted") {
                    // await this.getDevices();
                    await this.callObject.setInputDevicesAsync({
                        audioDeviceId: this.audioIn[0]?.deviceId,
                        videoSource: false
                    });
                }
            } else {
                const audioOn = this.callObject.localAudio();
                this.callObject.setLocalAudio(!audioOn);
                sessionStorage.setItem('audioOn', !audioOn);
            }
        },

        // Toggle local camera in use (on/off)
        async toggleVideo() {
            try {
                if (this.cameraBlocked) {
                    this.$refs.devicePermissionInfoBtn?.click()
                    return;
                }
                
                const videoOn = this.callObject.localVideo();
                // this.callObject.setLocalVideo(!videoOn);
                sessionStorage.setItem('videoOn', !videoOn);

                if (videoOn) {
                    //await this.callObject.updateInputSettings({ video: { processor: { type: "none" } } });
                    // artifical delay to wait for the processor to be reset
                    await new Promise(resolve => setTimeout(resolve, 100));

                    this.callObject.setLocalVideo(!videoOn);
                    await this.participant.tracks?.video?.track?.stop();
                    await this.callObject.setInputDevicesAsync({ videoDeviceId: false });
                    // console.log(this.participant.tracks)
                } else {
                    const processor = localStorage.getItem(BG_FILTER) ? JSON.parse(localStorage.getItem(BG_FILTER)) : null;

                    this.callObject.setLocalVideo(!videoOn);
                    // navigator.mediaDevices.getUserMedia({ video: true }).
                    await this.callObject.setInputDevicesAsync({ videoDeviceId: this.videoIn[0].deviceId });

                    if (processor.type) {
                        await this.selectEffect({ key: processor.type }, processor.config?.source)
                    }
                }
            } catch(e) {
                // handle error
                console.log(e)
            }
        },
        checkEventValidity() {
            let self = this;
            const eventId = self.$route.query?.eventId;
            if(!eventId) return; 
            const eventUrl = `event/${eventId}`
            self.$axios.get(eventUrl).then(response => {
                if(response.status == 200) {
                    const event_data =  response.data.data;
                    if(event_data.status == "completed") {
                        Swal.fire({
                            title: this.$t("warning"),
                            text: this.$t("event_is_expired"),
                            icon: 'info',
                            showConfirmButton: false,
                            showCloseButton: false,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            showCancelButton: false
                        });
                        self.isExpired = true;
                        return;
                    }
                    else if(event_data.status == "scheduled") {
                        Swal.fire({
                            title: this.$t("warning"),
                            text: this.$t("event_not_started_check_back_later"),
                            icon: 'info',
                            showConfirmButton: false,
                            showCloseButton: false,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            showCancelButton: false
                        });
                        self.isExpired = true;
                        return;
                    }
                }
            }).catch(e => {})
        }
    }
}
</script>